<template>
  <div>
    <el-table
      height="80vh"
      style="width: 100%"
      :data="teamList"
      border
      v-loading="loading"
    >
      <el-table-column
        prop="timer"
        label="报名日期"
        width="200"
        :formatter="formatterTimer"
      >
      </el-table-column>
      <el-table-column label="LOGO" width="80" align="center">
        <template slot-scope="scope">
          <img class="logo_url" :src="scope.row.logo_uri" />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="战队名" width="200">
      </el-table-column>
      <el-table-column prop="piaoshu" label="获赞数" width="100">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="auto">
        <template slot-scope="scope">
          <el-button @click="check(scope.row)" type="primary" size="small"
            >检查</el-button
          >
          <el-button type="danger" size="small" @click="delTeam(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="5"
      layout="prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getEnrollList, delEnrollTeam } from "../../../api/enroll/index";
export default {
  name: "AllTeam",
  mounted() {
    this.initData(1, 10);
  },
  data() {
    return {
      teamList: [],
      currentPage: 1,
      total: 200,
      loading: false,
    };
  },
  methods: {
    initData() {
      this.loading = true;
      getEnrollList(this.currentPage, 10)
        .then((res) => {
          this.teamList = res.data;
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    //格式化日期
    formatterTimer(row, column, cellValue) {
      let rec;
      let year = cellValue.slice(0, 4);
      let month = cellValue.slice(5, 7);
      let day = cellValue.slice(8, 10);
      let time = cellValue.slice(11, 19);
      rec = year + "年" + month + "月" + day + "日" + " " + time;
      return rec;
    },
    //检查事件
    check(row) {
      console.log(row);
      this.$store.commit("updateTeamName", row.name);
      this.$emit("searchteam");
    },
    //删除战队
    delTeam(row) {
      this.$confirm("是否删除该战队信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
      .then(() => {
        delEnrollTeam(row.name)
          .then(() => {
            this.$message.success("删除成功");
            this.initData();
          })
          .catch((err) => {
            this.$message.error("删除失败") + err;
          });
      })
      .catch(()=>{
          this.$message.warning('取消删除！')
      })
    },
    handleCurrentChange() {
      console.log(this.currentPage);
      this.initData(this.currentPage, 10);
    },
  },
};
</script>

<style scoped lang="less">
.logo_url {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
</style>
